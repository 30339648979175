import { RoutePattern } from '../../routes/RoutePattern';
import BG_IMG from '../../assets/images/photos-2024/3.webp';

export const tourData = [
  {
    name: 'songbyrd',
    date: 'sept 30, 2024',
    time: '7pm',
    where: 'washington',
    link: '#',
  },
  {
    name: 'the masquerade - hell',
    date: 'oct 2, 2024',
    time: '7pm',
    where: 'atlanta',
    link: '#',
  },
  {
    name: 'cafe du nord',
    date: 'oct 16, 2024',
    time: '7pm',
    where: 'san francisco',
    link: '#',
  },
  {
    name: 'subterranean',
    date: 'oct 1, 2024',
    time: '8pm',
    where: 'chicago',
    link: '#',
  },
  {
    name: "sob's",
    date: 'oct 5, 2024',
    time: '7pm',
    where: 'new york',
    link: '#',
  },
  {
    name: 'the roxy',
    date: 'oct 18, 2024',
    time: '8pm',
    where: 'los angeles',
    link: '#',
  },
];

export default function Tour() {
  return (
    <div
      id={RoutePattern.TOUR}
      className="pt-[10vh] pb-[1vh] -mt-[10vh] overflow-hidden relative z-10"
    >
      <div className="w-full h-full absolute top-0 left-0 -z-10 bg-dark">
        <img
          className="w-full h-full object-cover opacity-50"
          src={BG_IMG}
          alt="zyah belle"
        />
      </div>
      <div className="w-5/6 mx-auto flex flex-col justify-center relative z-10 min-h-[80vh]">
        <h4 className="px-[5vw] text-center font-bold text-[10vw] lg:text-[3vw] leading-[100%]">
          Coming Soon
        </h4>
        {/* <div className="grid md:grid-cols-2 gap-[5vh]">
          {tourData.map((item, idx) => (
            <div
              key={idx}
              className="bg-white w-full  text-dark p-[2vw] rounded--small"
            >
              <h5 className="heading--5 leading-[100%] heading">{item.name}</h5>
              <div className="flex flex-row justify-between items-center text-[2vw] md:text-[1vw]">
                <span className="capitalize">
                  {item.date} | {item.time} | {item.where}
                </span>
                <a
                  className="btn btn--dark"
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>tickets</span>
                </a>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-[5vh] text-center">
          <a
            className="btn btn--light"
            href="#"
            target="_blank"
            rel="noopener noreferrer"
          >
            view all shows
          </a>
        </div> */}
      </div>
      <div className="heading heading--2 mt-auto block">
        <h2 className="heading--text">SHOWS</h2>
      </div>
    </div>
  );
}
